<!-- Saltar al contenido principal -->
<p>
  <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
    (click)="navigateToButton()">{{ 'texto_743'
    |translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<header style="z-index: 90 !important; background-color: white;">
  <nav class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"
    style="z-index: 90 !important; padding-left: 5% !important; padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important;">
    <a href="#inicio" class="button-link">
      <img src="assets/img/logos/logo.png" width="160px" style="margin-left: -10px;"
        alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
    </a>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link smooth-scroll" style="color: #000000!important;"><app-translation></app-translation></a>
      </li>
    </ul>
  </nav>
</header>

<section id="mainContent" role="main" tabindex="-1">
  <div class="close">
    <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate">
      <i class="bi bi-x-square-fill"></i></a>
  </div>
  <div class="breadcrum-div">
    <nav aria-label="breadcrumb" class="nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' | translate }}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_252'| translate }}</li>
      </ol>
    </nav>
  </div>
  <div class="container">
    <div class="left-side">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <!-- welcome message -->
        <div class="welcome-message">

          <h1>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 2rem;">{{ 'texto_5' | translate
                }}</span>
            </div>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 4rem">{{ 'texto_6' | translate
                }}</span>
            </div>
          </h1>
        </div>
        <!-- End welcome message -->
      </div>
    </div>
    <div class="right-side">

      <div class="form-container">
        <img class="logo" src="assets/img/logos/logo.png"
          alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">

        <div class="md-form mb-4">
          <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon>
          <label class="label-form" for="defaultForm-email">{{ 'texto_38' | translate }}</label>
          <input type="email" autocomplete="email" id="defaultForm-email" [formControl]="loginFormModalEmail"
            class="form-control" mdbInput mdbValidate aria-describedby="email-error"
            [attr.aria-invalid]="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched) ? 'true' : 'false'">

          <mdb-error id="email-error" role="alert"
            *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="margin-left: 77%;top:-12px !important;"
            *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="md-form mb-3">
          <mdb-icon fas icon="lock" class="prefix grey-text" style="display: contents;"></mdb-icon>
          <label class="label-form" for="defaultForm-pass">{{ 'texto_387' | translate }}</label>
          <input type="password" autocomplete="new-password" id="defaultForm-pass"
            [formControl]="loginFormModalPassword" class="form-control" mdbInput mdbValidate
            aria-describedby="password-error"
            [attr.aria-invalid]="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched) ? 'true' : 'false'">

          <mdb-error id="password-error" role="alert"
            *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="margin-left: 77%;top:-12px !important;"
            *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
          <div [hidden]="!loginOk" class="alert alert-danger" role="alert" style="text-align: center !important;">
            <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_522' | translate }}</span>
          </div>
          <div [hidden]="!loginOkBlock" class="alert alert-danger" role="alert" style="text-align: center !important;">
            <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_523' | translate }}</span>
          </div>
          <div [hidden]="!loginNoUser" class="alert alert-danger" role="alert" style="text-align: center !important;">
            <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_524' | translate }}</span>
          </div>
          <div [hidden]="!incorrectPassword" class="alert alert-danger" role="alert"
            style="text-align: center !important;">
            <span style="font-size: 0.93rem;"><b>Error: </b>{{ 'texto_750' | translate }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-center btn-padding">
          <button (click)="login()" [disabled]="validatingForm.invalid" class="btn" mdbWavesEffect>{{ 'texto_252' |
            translate }}</button>
        </div>
        <div [hidden]="!HabilitarSSO">
          <div class="d-flex justify-content-center btn-padding">
            <button (click)="login2()" class="btn" mdbWavesEffect>{{ 'texto_535' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
