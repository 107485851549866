<!-- Saltar al contenido principal -->
<p>
  <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
    (click)="navigateToButton()">{{ 'texto_743'
    |translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<header style="z-index: 90 !important; background-color: white;">
  <nav class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"
    style="z-index: 90 !important; padding-left: 5% !important; padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important;">
    <a href="#inicio" class="button-link">
      <img src="assets/img/logos/logo.png" width="160px" style="margin-left: -10px;"
        alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
    </a>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link smooth-scroll" style="color: #000000!important;"><app-translation></app-translation></a>
      </li>
    </ul>
  </nav>
</header>

<section id="mainContent" role="main" tabindex="-1">
  <div class="close">
    <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate"><i
        class="bi bi-x-square-fill"></i></a>
  </div>

  <div class="breadcrum-div">
    <nav aria-label="breadcrumb" class="nav">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' | translate }}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{ 'texto_147' | translate }}</li>
      </ol>
    </nav>
  </div>

  <div class="container">
    <div class="left-side">
      <div class="col-md-10 col-sm-12 px-0 mx-0">
        <!-- welcome message -->
        <div class="welcome-message">
          <h1>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 2rem;">{{ 'texto_5' | translate
                }}</span>
            </div>
            <div>
              <span style="color:var(--color-txt-titulo);font-weight: bold; font-size: 4rem">{{ 'texto_6' | translate
                }}</span>
            </div>
          </h1>
        </div>
        <!-- End welcome message -->
      </div>
    </div>
    <div class="right-side">
      <div class="form-container">
        <img class="logo" src="assets/img/logos/logo.png"
          alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">

        <!-- <h1 class="title-form">{{ 'texto_360' | translate }}</h1> -->
        <p class="description-form">{{ 'texto_361' | translate }}</p>
        <div class="md-form mb-4">
          <mdb-icon fas icon="id-badge" class="prefix grey-text"></mdb-icon>
          <label class="label-form" for="formId">* {{ 'texto_362' | translate }}:</label>
          <input type="text" id="formId" class="form-control" [formControl]="formId" mdbInput mdbValidate
            aria-describedby="formId-error"
            [attr.aria-invalid]="formId.invalid && (formId.dirty || formId.touched) ? 'true' : 'false'">
          <mdb-error id="formId-error" role="alert" *ngIf="formId.invalid && (formId.dirty || formId.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="margin-left: 80%;top:-12px !important;"
            *ngIf="formId.valid && (formId.dirty || formId.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="md-form mb-3">
          <mdb-icon fas icon="lock" class="prefix grey-text"></mdb-icon>
          <label class="label-form" for="formPin">* {{ 'PIN' | translate }}:</label>
          <input type="password" id="formPin" class="form-control input-form" [formControl]="formPin" mdbInput
            mdbValidate aria-describedby="formPin-error"
            [attr.aria-invalid]="formPin.invalid && (formPin.dirty || formPin.touched) ? 'true' : 'false'">
          <mdb-error id="formPin-error" role="alert" *ngIf="formPin.invalid && (formPin.dirty || formPin.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success style="margin-left: 80%;top:-12px !important;"
            *ngIf="formPin.valid && (formPin.dirty || formPin.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="d-flex justify-content-center">
          <button (click)="enviar()" style="color:#fff" mdbBtn rounded="true" class="btn" mdbWavesEffect>{{ 'texto_41' |
            translate }}</button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
